@import url('https://fonts.googleapis.com/css2?family=Jaini+Purva&family=Kalam:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional custom styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Jaini Purva', 'Kalam', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

:root {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul,
li {
  list-style: disc;
  /* Or any desired style */
  margin: 0;
  padding: 0;
  text-align: start;
}

.touchme {
  position: absolute;
  animation: swipe 8s infinite;
}

@keyframes swipe {
  0% {
    right: 50px;
  }

  50% {
    right: 0px;
  }

  75% {
    right: 50px;
  }

  100% {
    right: 50px;
  }
}

.scrolling-text {
  white-space: nowrap;
  animation: scrollText 30s linear infinite;
}

@keyframes scrollText {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.gallery-item {
  position: relative;
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.gallery-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
}

.control-button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: 'background' 0.3s;
}

.control-button:hover {
  background: rgba(0, 0, 0, 0.8);
}