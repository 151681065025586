/* General styles for feature-div */
.feature-div {
    font-size: 25px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    text-decoration: none;
    flex: 1;
    margin: 0.5vw;
}

/* Flex container with spacing adjustments */
.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1vw; /* Default gap between boxes */
}

/* Small screen adjustments */
@media screen and (max-width: 767px) {
    .flex-container {
        flex-wrap: wrap;
        gap: 2vw; /* Adjust gap between boxes */
    }

    .feature-div {
        width: 48%; /* Set width so two links fit side by side */
    }
}

/* Medium screen adjustments */
@media screen and (min-width: 768px) {
    .feature-div {
        width: 23vw; /* Adjust width to fit images */
    }
    .flex-container {
        gap: 2vw; /* Adjust gap between boxes */
    }
}

/* Large screen adjustments */
@media screen and (min-width: 1024px) {
    .feature-div {
        width: 20vw; /* Adjust width to fit images */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .flex-container {
        gap: 1.5vw; /* Adjust gap between boxes */
    }
}

/* Styles for arrows */
.arrow-button {
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    position: absolute;
}

.arrow-left {
    left: 10px;
}

.arrow-right {
    right: 10px;
}

.feature-div:hover{
    color:white;
}
